import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import menu from './modules/menu'
import user from '@/store/modules/user'
import loading from '@/store/modules/loading'
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { menu, user, loading },
})
