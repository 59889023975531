import request from '@/utils/request'

export function ossconfig() {
  return request({
    url: '/work/oss/web_config',
    method: 'get',
  })
}
export function getWecomConfig(data) {
  return request({
    url: '/work/jssdk',
    method: 'post',
    data,
  })
}
