/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-05-27 11:29:40
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-11-16 17:19:48
 * @Description: 
 */
import storage from 'store'
import store from '@/store'
import {
  ACCESS_TOKEN,
  USER_INFO
} from '@/utils/request'
import {
  authLogin,
  getAuthUrl
} from '@/api/user'

/**
 * @param to 路由信息
 * @description 判断url中是否有authCode或者token 如果有则保存 如果没有则跳过
 * */
export default function saveToken(to) {
  return new Promise((resolve) => {
    /*let entry;
    if (wx?.invoke) {
      wx.invoke("getContext", {}, function (res) {
        if (res.err_msg == "getContext:ok") {
          entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
          // shareTicket = res.shareTicket; //可用于调用getShareInfo接口
          console.log("entry", entry);
        } else {
          //错误处理
        }
      });
    }*/
    let url = window.location.href
    let auth_code = url.split('auth_code=')[1]?.split('#/')[0]
    // let token = url.split('token=')[1]?.split('#/')[0]
    let token = ''
    if (window.android) {
      const jsonStr = window.android.getJsonStr()
      const data = JSON.parse(jsonStr || '')

      token = data.token || ''
    } else {
      token = url.split('token=')[1]?.split('#/')[0]
    }

    if (token) {
      storage.clearAll()
      store.commit('user/SET_TOKEN', token)
      storage.set(ACCESS_TOKEN, token)

      //重定向到配置好的env变量 去掉url中的参数
      if (to.query && to.query.redirect) {
        window.location.href =
          `${process.env.VUE_APP_REDIRECT_URL}#` + to.query.redirect
      } else {
        window.location.href = process.env.VUE_APP_REDIRECT_URL
      }
      resolve()
    } else if (auth_code) {
      //通过authCode 登录
      authLogin({
        auth_code,
        login_type: 'json',
      }).then((res) => {
        if (res.code === 0) {
          store.commit('user/SET_TOKEN', res.data.token)
          storage.set(ACCESS_TOKEN, res.data.token)
          storage.set(USER_INFO, res.data.user)
          // 兼容话机
          if (window.android) {
            window.android.saveJsonStr(
              JSON.stringify({
                token: res.data.token
              })
            )
          }
          //重定向到配置好的env变量 去掉url中的参数
          window.location.replace(process.env.VUE_APP_REDIRECT_URL)
          resolve()
        }
      })
    } else {
      // let token = storage.get(ACCESS_TOKEN)
      let token = ''
      if (window.android) {
        const jsonStr = window.android.getJsonStr()
        const data = JSON.parse(jsonStr || '')

        token = data.token || ''
      } else {
        token = storage.get(ACCESS_TOKEN)
      }

      if (isWxBrowser() && !token) {
        getAuthUrl().then((res) => {
          console.log(res)
          if (res.code === 0) {
            console.log(res)
            window.location.replace(res.data.url)
          }
        })
      }
      resolve()
    }
  })
}

function isWxBrowser() {
  let ua = navigator.userAgent.toLowerCase()
  let isWx = ua.match(/MicroMessenger/i) == 'micromessenger'
  if (!isWx) {
    return false
  } else {
    return ua.match(/WxWork/i) == 'wxwork'
  }
}