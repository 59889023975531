export default {
  namespaced: true,
  state: {
    subMenu: null,
    //是否在dashboard布局下
    isInDashbord: false,
  },
  getters: {
    formatSubMenu(state) {
      return [state.subMenu.name]
    },
  },
  mutations: {
    setSubMenu(state, payload) {
      state.subMenu = payload
    },
    setIsInDashbord(state, payload) {
      state.isInDashbord = payload
    },
  },
  actions: {},
}
