import _ from 'lodash'
import topMenu from '@/config/topMenu'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import router from '@/router'
import { ACCESS_TOKEN } from '@/utils/request'
import storage from 'store'
import { Modal } from 'ant-design-vue'
import saveToken from '@/utils/saveToken'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const topMenu_ = _.cloneDeep(topMenu)
const allowList = ['Login', 'HomePage', 'Register', 'OfficialLogin', 'Forget'] // no redirect allowList

// 路由跳转前
router.beforeEach(async (to, from, next) => {
  // 判断是否有token
  await saveToken(to)
  // 销毁弹出的确认窗
  Modal.destroyAll()
  NProgress.start()
  // 加title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title =
      '订个包间 - 更好用的餐厅包间预订系统 | 包厢预订 | 饭店包间预订'
  }
  // 未登录跳转登录
  if (storage.get(ACCESS_TOKEN)) {
    if (to.name) {
      // 当前选中菜单 判断方式 子路由path必须跟在父路由path之后   /父菜单name/子菜单name
      const headMenu = topMenu_.find((item) =>
        to.path.includes(`/${item.name}`)
      )
      store.commit('menu/setSubMenu', headMenu)
      next()
    } else {
      next('/404')
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ name: 'OfficialLogin', query: { redirect: to.fullPath } })
      NProgress.done() // 如果当前页面是login将不会 afterEach，所以手动处理它
    }
  }
})

// 路由跳转后
router.afterEach(() => {
  NProgress.done()
})
