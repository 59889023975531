<template>
  <div id="app">
    <a-config-provider :locale="zhCN">
      <router-view />
    </a-config-provider>
    <a-back-top ref="back" v-show="false" />
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { agentConfig, config } from '@/utils/wecom'
import { getWecomConfig } from '@/api'
import storage from 'store'
import { ACCESS_TOKEN, USER_INFO } from '@/utils/request'

export default {
  data() {
    return {
      zhCN,
    }
  },
  methods: {
    backTop() {
      this.$refs.back.scrollToTop()
    },
    async configWecom() {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        const config_ = await this.getConfig('config')
        console.log('config', config_)
        // eslint-disable-next-line no-undef
        await config(config_, wx)
      }
      const agentConfigInfo = await this.getConfig('agent_config')
      console.log('agent_config', agentConfigInfo)
      // eslint-disable-next-line no-undef
      await agentConfig(agentConfigInfo, wx)
        .then((res) => console.log('agent_config 成功', res))
        .catch((err) => console.error('agent_config 失败', err))
      // 若一切正常，此时可以在 window 上看到 WWOpenData 对象
      console.info('window.WWOpenData', window.WWOpenData)
      const WWOpenData = window.WWOpenData
      /* eslint-disable-next-line */
      if (WWOpenData.checkSession) {
        /* eslint-disable-next-line */
        WWOpenData.checkSession({
          success: () => {
            console.info('open-data 登录态校验成功')
            WWOpenData.bind(this.$el)
          },
          fail(err) {
            console.error(err)
          },
        })
      }
      if (WWOpenData.on) {
        /**
         * ww-open-data 元素数据发生变更时触发
         */
        WWOpenData.on('update', () => {
          // const openid = event.detail.element.getAttribute("openid");
          // console.info("渲染数据发生变更", openid, event.detail.hasData);
        })
        /**
         * ww-open-data 获取数据失败时触发
         */
        WWOpenData.on('error', () => {
          // console.error("获取数据失败", err);
        })
      }
    },
    getConfig(configType = 'agent_config') {
      return new Promise((resolve, reject) => {
        getWecomConfig({
          url: location.href.split('#')[0],
        }).then((res) => {
          resolve();
          return;
          if (res.code === 0) {
            const config = res.data.config
            const agentConfigInfo = res.data.agent_config
            resolve(configType === 'agent_config' ? agentConfigInfo : config)
          } else {
            reject(res)
          }
        })
      })
    },
  },
  mounted() {
    const token = storage.get(ACCESS_TOKEN)
    const userInfo = storage.get(USER_INFO)
    if (token) {
      if (process.env.NODE_ENV !== 'production') return
      if (userInfo && !userInfo.shop.corpid) return
      this.configWecom()
    }
  },
}
//TODO 测试推送权限
</script>
<style lang="less">
body {
  height: 100%;
}

#app {
  height: 100%;
}
</style>
